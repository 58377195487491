import {
  faPlus,
  faTrashCan,
  faPen,
  faArrowLeftLong,
  faStar,
  faPrint,
  faFileExcel,
  faFile,
  faCalendarDays,
  faCircleXmark,
  faCircleCheck,
  faXmark,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

const fortawesomeIcons = {
  faPlus,
  faTrashCan,
  faPen,
  faArrowLeftLong,
  faStar,
  faPrint,
  faFileExcel,
  faFile,
  faCalendarDays,
  faCircleXmark,
  faCircleCheck,
  faXmark,
  faBars,
};

export { fortawesomeIcons };
