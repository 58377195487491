import { join, toLower, upperFirst, words } from 'lodash';

const textToCamelCase = (text) => {
  const wordsArray = words(text);
  const camelCaseWords = wordsArray.map((word, index) => {
    return index === 0 ? toLower(word) : upperFirst(toLower(word));
  });
  return join(camelCaseWords, '');
};

export { textToCamelCase };
