let lastInnerHeight = window.innerHeight;

export const windowResize = ({ stickyPosition }) => {
  if (!stickyPosition.value) return;

  if (window.innerHeight < lastInnerHeight) {
    stickyPosition.value.bottom = '0px';
  } else if (window.innerHeight > lastInnerHeight) {
    stickyPosition.value.bottom = '0.1px';
  }

  lastInnerHeight = window.innerHeight;
};

export const avoidDisplacementOfResults = ({ stickyPosition }) => {
  window.addEventListener('resize', () => windowResize({ stickyPosition }));
};
