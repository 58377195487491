import mixpanel from 'mixpanel-browser';
import {
  loginPropertiesAdapter,
  registerPropertiesAdapter,
  useMyOwnDataDailyConsumptionResultsAdapter,
} from './mixpanelPropertiesAdapters';

export class MixpanelTracking {
  static _instance = new MixpanelTracking();

  static getInstance() {
    if (!MixpanelTracking._instance) return (MixpanelTracking._instance = new MixpanelTracking());

    return this._instance;
  }

  constructor() {
    if (MixpanelTracking._instance)
      throw new Error(
        'Error: Instance creation of MixpanelTracking is not allowed. Use MixpanelTracking.getInstance() instead.',
      );

    const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN;

    mixpanel.init(mixpanelToken || '', {
      debug: true,
      ignore_dnt: true,
    });
  }

  _track(eventName, properties) {
    mixpanel.track(eventName, properties);
  }

  trackPageViewed() {
    this._track('page_viewed');
  }
  trackLogin(user) {
    this._track('user_login', loginPropertiesAdapter(user));
  }
  trackRegister(user) {
    this._track('user_register', registerPropertiesAdapter(user));
  }
  trackUseMyOwnDataDailyConsumptionResults(trackProperties) {
    this._track(
      'use_my_own_data_daily_consumption_results',
      useMyOwnDataDailyConsumptionResultsAdapter(trackProperties),
    );
  }
  trackHelpMeModelAFacility() {
    // this._track('help_me_model_a_facility');
  }
}
