import { parseNumber } from '@/common/utils';
import { DEVICE_TYPES_TO_ANESTHESIA } from '@/modules/ConsumablesAndCost/utilities/modelCostAndConsumables';

const calculatePercentage = (patients, totalPatients) => {
  const percentaje = Math.round((parseNumber(patients) / parseNumber(totalPatients)) * 100) || 0;
  return percentaje;
};

const isAnAnesthesiaDevice = (deviceType) => {
  return DEVICE_TYPES_TO_ANESTHESIA.includes(deviceType);
};

export { calculatePercentage, isAnAnesthesiaDevice };
