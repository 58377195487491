const ConsumablesCost = () => import('@/modules/ConsumablesAndCost/views/ConsumablesCost.vue');

const moduleRoute = {
  name: 'consumables-cost',
  path: 'consumables-cost',
  component: ConsumablesCost,
};

export default (router) => {
  router.addRoute('main', moduleRoute);
};
