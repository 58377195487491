import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/cylinder/cylinderduration',
  },
  {
    path: '/',
    name: 'main',
    component: () => import('@/common/components/layout/Main.vue'),
    children: [
      {
        path: '/',
        redirect: () => 'cylinder/cylinderduration',
      },
    ],
  },
  {
    path: '/Ecuador',
    name: 'Ecuador',
    component: () => import('@/common/components/ExternalRedirect.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
