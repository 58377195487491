import { createPinia } from 'pinia';
import { createApp } from 'vue';

import App from './App.vue';

import { i18n } from './i18n';
import './registerServiceWorker';
import router from './router';

import { fortawesomeIcons } from '@/common/utils';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FloatingVue from 'floating-vue';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import JsonCSV from 'vue-json-csv';

import '@/assets/tailwind.css';
import 'floating-vue/dist/style.css';

library.add(fortawesomeIcons);

import ConsumablesAndCost from '@/modules/ConsumablesAndCost';
import Cylinder from '@/modules/Cylinder';
import About from '@/modules/General';
import Oxygen from '@/modules/Oxygen';
import Auth from '@/modules/auth';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { registerModules } from './RegisterModules';

registerModules({
  about: About,
  cylinder: Cylinder,
  oxygen: Oxygen,
  auth: Auth,
  consumablesAndCost: ConsumablesAndCost,
});

createApp(App)
  .use(createPinia())
  .use(i18n)
  .use(router)
  .use(VCalendar, {})
  .use(Toast)
  .component('fa-icon', FontAwesomeIcon)
  .component('downloadCsv', JsonCSV)
  .use(FloatingVue, {
    themes: {
      'custom-tooltip': {
        $extend: 'tooltip',
        $resetCss: true,
      },
    },
  })
  .mount('#app');
