import { createI18n } from 'vue-i18n';

const supportedLanguages = ['en', 'es', 'vi', 'fr', 'pt'];

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getLanguageFromURL() {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');
  if (langParam && supportedLanguages.includes(langParam)) {
    return langParam;
  }
  return null;
}

const getDefaultLanguageFromUserMachine = () => {
  const localeLanguage = window.navigator.language.slice(0, 2);
  return supportedLanguages.includes(localeLanguage) ? localeLanguage : 'en';
};

const i18n = createI18n({
  legacy: false,
  locale: getLanguageFromURL() || getDefaultLanguageFromUserMachine(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  globalInjection: true,
});

export { i18n };
