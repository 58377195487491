import { ref, onMounted, onUnmounted } from 'vue';

export function useOnlineStatus() {
  const isOnline = ref(navigator.onLine);

  function handleOnlineStatus() {
    isOnline.value = true;
  }

  function handleOfflineStatus() {
    isOnline.value = false;
  }

  onMounted(() => {
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);
  });

  onUnmounted(() => {
    window.removeEventListener('online', handleOnlineStatus);
    window.removeEventListener('offline', handleOfflineStatus);
  });

  return {
    isOnline,
  };
}
