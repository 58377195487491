const CylinderSize = () => import('@/modules/Cylinder/views/CylinderSize.vue');
const CylinderDuration = () => import('@/modules/Cylinder/views/CylinderDuration.vue');

const moduleRoute = {
  name: 'cylinder',
  path: 'cylinder',
  children: [
    {
      name: 'cylindersize',
      path: 'cylindersize',
      component: CylinderSize,
    },
    {
      name: 'cylinderduration',
      path: 'cylinderduration',
      component: CylinderDuration,
    },
  ],
};

const redirectRoute = {
  ...moduleRoute,
  path: '/cylinder/:pathMatch(.*)*',
  redirect: '/cylinder/cylindersize',
};

export default (router) => {
  router.addRoute('main', redirectRoute);
};
