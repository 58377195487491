const LOCALE_LANGUAGE = window.navigator.language;

const numberToLocaleFormat = (number) => {
  return typeof number === 'number' ? number.toLocaleString(LOCALE_LANGUAGE) : number;
};

const numberFormatRound = (number) => {
  return numberToLocaleFormat(Math.round(number * 10) / 10);
};

function capitalizeFirstLetter(text) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

function pressureToBar({ pressureValue, pressureUnit }) {
  if (pressureUnit === 'KPA') {
    return pressureValue / 101;
  }
  if (pressureUnit === 'PSI') {
    return pressureValue / 14.7;
  }
  return pressureValue;
}

function truncateNumberToOneDecimal(value) {
  if (typeof value !== 'number' || isNaN(value)) {
    throw new Error('Input value is not a number');
  }

  const formattedStr = value.toString();
  const decimalSeparatorIndex = formattedStr.indexOf('.');
  const truncatedNumberStr = formattedStr.slice(0, decimalSeparatorIndex + 2);

  return truncatedNumberStr;
}

function preventNonNumericInput(event) {
  const NON_NUMERIC_REGEX = /\D/;
  if (NON_NUMERIC_REGEX.test(event.key)) {
    event.preventDefault();
  }
}

function truncateNumberToEightDigits(number) {
  const truncatedNumber = number.toString().slice(0, 8);
  return Number(truncatedNumber);
}

const parseNumber = (inputValue) => {
  const VALID_NUMBER_CHARACTERS = /[^0-9.]/g;

  const parsed = parseFloat(inputValue.toString().replace(VALID_NUMBER_CHARACTERS, ''));
  return isNaN(parsed) ? 0 : parsed;
};

const truncateToTwoDecimals = (num) => {
  return Number.isInteger(num) ? num : Math.round(num * 100) / 100;
};

export {
  capitalizeFirstLetter,
  numberToLocaleFormat,
  numberFormatRound,
  parseNumber,
  pressureToBar,
  preventNonNumericInput,
  truncateNumberToEightDigits,
  truncateNumberToOneDecimal,
  truncateToTwoDecimals,
};
