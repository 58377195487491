const O2demand = () => import('@/modules/Oxygen/views/O2demand.vue');
const O2supply = () => import('@/modules/Oxygen/views/O2supply.vue');
const Sp2topao2 = () => import('@/modules/Oxygen/views/Sp2topao2.vue');

const moduleRoute = {
  name: 'oxygen',
  path: 'oxygen',
  children: [
    {
      name: 'o2demand',
      path: 'o2demand',
      component: O2demand,
    },
    {
      name: 'o2supply',
      path: 'o2supply',
      component: O2supply,
    },
    {
      name: 'sp2topao2',
      path: 'sp2topao2',
      component: Sp2topao2,
    },
  ],
};

const redirectRoute = {
  ...moduleRoute,
  path: '/oxygen/:pathMatch(.*)*',
  redirect: '/oxygen/o2demand',
};

export default (router) => {
  router.addRoute('main', redirectRoute);
};
