const footerLinks = [
  {
    name: 'Contact Us',
    link: 'https://opencriticalcare.org/contact/',
  },
  {
    name: 'Copyright',
    link: 'https://opencriticalcare.org/terms/',
  },
  {
    name: 'Terms of Use',
    link: 'https://opencriticalcare.org/terms/',
  },
];

const imagesPaths = [
  { path: 'usaid', style: 'max-h-10 w-auto' },
  { path: 'star', style: 'max-h-8' },
  { path: 'AAU', style: 'max-h-12 w-auto' },
  { path: 'CHESA', style: 'max-h-9 w-auto' },
];

const twitterLink = 'https://twitter.com/opencritcareorg';

export { imagesPaths, footerLinks, twitterLink };
