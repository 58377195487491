import { onMounted, onUnmounted } from 'vue';

function useClickOutSide(target, callback) {
  if (!target) return;
  const listener = (e) => {
    if (target.value?.contains(e.target)) {
      return;
    } else {
      callback();
    }
  };

  onMounted(() => {
    window.addEventListener('mouseup', listener);
  });

  onUnmounted(() => {
    window.removeEventListener('mouseup', listener);
  });

  return listener;
}

export { useClickOutSide };
