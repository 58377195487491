const TYPES_OF_HEADERS_COSTS = {
  CONSUMABLES: 'Consumables',
  CONSUMPTION_PER_DEVICE: 'O₂ Consumption (LPM) per Device',
  COST_PER_UNIT: 'Cost Per Unit',
  DELIVERY_DEVICE: 'Delivery Device',
  LITERS_PER_DAY: 'O₂ Liters per Day all Patients',
  NUMBER_OF_PATIENTS: 'Number of Patients',
  REPLACE_INTERVAL: 'Replacement Interval',
  SETTINGS: 'Settings',
  TOTAL_COST: 'Total Cost',
  TOTAL_QUANTITY: 'Total Quantity',
};

const PROPERTIES_LIST = {
  CONSUMPTION: 'consumption',
  COST_PER_UNIT: 'costPerUnit',
  LITERS_PER_DAY_ALL_PATIENTS: 'litersPerDayAllPatients',
  PATIENTS: 'patients',
  REPLACEMENT_INTERVAL: 'replacementInterval',
  SETTINGS: 'settings',
  TITLE: 'title',
  TOTAL_COST: 'totalCost',
  TOTAL_QUANTITY: 'totalQuantity',
};

const DEVICE_TYPE = {
  ANESTHESIA_MACHINE_100_FIO2_AT_2LPM: 'Anesthesia Machine 100% FiO2 at 2 LPM',
  ANESTHESIA_MACHINE_50_FIO2_AT_2LPM: 'Anesthesia Machine 50% FiO2 at 2 LPM',
  ANESTHESIA_MACHINE_WITH_VENTILATOR: 'Anesthesia Machine (with ventilator)',
  CPAP_OR_NIPPV: 'CPAP or NIPPV',
  FACEMASK: 'Facemask',
  FACEMASK_WITH_RESERVOIR: 'Facemask with reservoir',
  HIGH_FLOW_NASAL_CANNULA: 'High Flow Nasal Cannula',
  NASAL_CANNULA: 'Nasal Cannula',
  OXYGEN: 'Oxygen',
  VENTILATOR: 'Ventilator',
};

const CONSUMABLE_TYPE = {
  CIRCUIT: 'Circuit',
  CO2_ABSORBENT: 'CO2 absorbent',
  ENDOTRACHEAL_TUBE: 'Endotracheal tube',
  HUMIDIFICATION_H2O: 'Humidification (H2O & tubing)',
  INLINE_SUCTION_CATHETER: 'Inline Suction catheter',
  MASK: 'Mask',
  MOISTURE_EXCHANGER: 'Moisture exchanger',
  PATIENT_INTERFACE: 'Patient interface',
  RESERVOIR_BAG: 'Reservoir bag',
  SUCTION_CATHETER: 'Suction catheter',
  VENTILATOR_FILTER: 'Ventilator filter',
};

const CURRENCY_TYPE = {
  USD: 'USD',
};

const OPTIONS_CURRENCY = [{ text: CURRENCY_TYPE.USD, value: CURRENCY_TYPE.USD }];

const EDIT_FLOW_PARAMETERS = 'Edit Flow Parameters';
const EDIT_FLOW_RATE = 'Edit Flow Rate';
const FLOW_PARAMETERS = 'flow-parameters';
const FLOW_RATE = 'flow-rate';
const FLOW_RATE_LPM = 'Flow Rate (LPM)';

const MODEL_COST_AND_CONSUMABLES = [
  {
    id: '1',
    title: DEVICE_TYPE.NASAL_CANNULA,
    consumption: 3,
    costPerUnit: 0.44,
    isSelected: true,
    litersPerDayAllPatients: 0,
    patients: 28,
    percentage: 0,
    replacementInterval: 11,
    totalCost: 0.44,
    totalQuantity: 1,
    subtitle: '',
    edit: {
      type: FLOW_RATE,
      title: EDIT_FLOW_RATE,
      inputs: [
        {
          name: 'flowRate',
          text: FLOW_RATE_LPM,
          type: 'input',
          value: 3,
        },
      ],
    },
    consumables: [],
  },
  {
    id: '2',
    title: DEVICE_TYPE.FACEMASK,
    consumption: 8,
    costPerUnit: 0.61,
    isSelected: true,
    litersPerDayAllPatients: 0,
    patients: 3,
    percentage: 0,
    replacementInterval: 9,
    totalCost: 0.61,
    totalQuantity: 1,
    subtitle: '',
    edit: {
      type: FLOW_RATE,
      title: EDIT_FLOW_RATE,
      inputs: [
        {
          maxValue: 100,
          minValue: 0,
          name: 'flowRate',
          text: FLOW_RATE_LPM,
          type: 'input',
          value: 8,
        },
      ],
    },
    consumables: [],
  },
  {
    id: '3',
    title: DEVICE_TYPE.FACEMASK_WITH_RESERVOIR,
    consumption: 15,
    costPerUnit: 0.88,
    isSelected: true,
    litersPerDayAllPatients: 0,
    patients: 9,
    percentage: 0,
    replacementInterval: 9,
    totalCost: 0.88,
    totalQuantity: 1,
    subtitle: '',
    edit: {
      type: FLOW_RATE,
      title: EDIT_FLOW_RATE,
      inputs: [
        {
          maxValue: 100,
          minValue: 0,
          name: 'flowRate',
          text: FLOW_RATE_LPM,
          type: 'input',
          value: 15,
        },
      ],
    },
    consumables: [],
  },
  {
    id: '4',
    title: DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA,
    consumption: 40,
    costPerUnit: 12,
    isSelected: true,
    litersPerDayAllPatients: 0,
    patients: 28,
    percentage: 0,
    replacementInterval: 1,
    subtitle: '@40 (LPM) flow rate, FiO₂ 1',
    totalCost: 30,
    totalQuantity: 8,
    edit: {
      type: FLOW_PARAMETERS,
      title: EDIT_FLOW_PARAMETERS,
      inputs: [
        {
          maxValue: 100,
          minValue: 0,
          name: 'flowRate',
          text: FLOW_RATE_LPM,
          type: 'input',
          value: 40,
        },
        {
          maxValue: 100,
          minValue: 21,
          name: 'fio2',
          step: 1,
          text: 'FiO2',
          value: 100,
        },
      ],
    },
    consumables: [
      {
        id: '41',
        title: CONSUMABLE_TYPE.CIRCUIT,
        costPerUnit: 14.5,
        replacementInterval: 20,
        totalQuantity: 1,
        totalCost: 14.5,
      },
      {
        id: '42',
        title: CONSUMABLE_TYPE.HUMIDIFICATION_H2O,
        costPerUnit: 10.81,
        replacementInterval: 14,
        totalQuantity: 1,
        totalCost: 10.81,
      },
      {
        id: '43',
        title: CONSUMABLE_TYPE.PATIENT_INTERFACE,
        costPerUnit: 22.11,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 22.11,
      },
    ],
  },
  {
    id: '5',
    title: DEVICE_TYPE.CPAP_OR_NIPPV,
    consumption: 22,
    costPerUnit: 12,
    isSelected: true,
    litersPerDayAllPatients: 0,
    patients: 4,
    percentage: 0,
    replacementInterval: 10,
    subtitle: '@12 (LPM) minute ventilator, 0 (LPM) bias flow, Leak 10 (LPM), FiO₂  1',
    totalCost: 20,
    totalQuantity: 11,
    edit: {
      type: 'vent',
      title: 'Edit Vent Parameters',
      inputs: [
        {
          name: 'minuteVentilator',
          text: 'Minute Ventilation',
          type: 'input',
          value: 12,
        },
        {
          maxValue: 15,
          name: 'biasFlow',
          text: 'Bias Flow',
          type: 'input',
          value: 0,
        },
        {
          maxValue: 50,
          name: 'circuitLeak',
          text: 'Circuit Leak',
          type: 'input',
          value: 10,
        },
        {
          maxValue: 100,
          minValue: 21,
          name: 'fio2',
          step: 1,
          text: 'FiO2',
          value: 100,
        },
        {
          maxValue: 60,
          minValue: 0,
          name: 'rr',
          text: 'RR',
          value: 20,
        },
        {
          maxValue: 5,
          minValue: 0,
          name: 'expiratoryTime',
          step: 0.1,
          text: 'Expiratory Time',
          value: 2,
        },
      ],
    },
    consumables: [
      {
        id: '51',
        title: CONSUMABLE_TYPE.CIRCUIT,
        costPerUnit: 14.5,
        replacementInterval: 8,
        totalQuantity: 23,
        totalCost: 333.5,
      },
      {
        id: '52',
        title: CONSUMABLE_TYPE.VENTILATOR_FILTER,
        costPerUnit: 1.7,
        replacementInterval: 14,
        totalQuantity: 13,
        totalCost: 22.1,
      },
      {
        id: '53',
        title: CONSUMABLE_TYPE.MOISTURE_EXCHANGER,
        costPerUnit: 2.02,
        replacementInterval: 14,
        totalQuantity: 13,
        totalCost: 26.26,
      },
      {
        id: '54',
        title: CONSUMABLE_TYPE.HUMIDIFICATION_H2O,
        costPerUnit: 10.81,
        replacementInterval: 8,
        totalQuantity: 23,
        totalCost: 248.63,
      },
      {
        id: '55',
        title: CONSUMABLE_TYPE.PATIENT_INTERFACE,
        costPerUnit: 17.06,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 17.06,
      },
      {
        id: '56',
        title: CONSUMABLE_TYPE.SUCTION_CATHETER,
        costPerUnit: 0.65,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 0.65,
      },
    ],
  },
  {
    id: '6',
    title: DEVICE_TYPE.VENTILATOR,
    consumption: 12,
    costPerUnit: 12,
    isSelected: true,
    litersPerDayAllPatients: 0,
    patients: 24,
    percentage: 0,
    replacementInterval: 24,
    subtitle: '@12 (LPM) minute ventilator, 0 (LPM) bias, flow, FiO₂ 1',
    totalCost: 1181.64,
    totalQuantity: 1,
    edit: {
      type: 'vent',
      title: 'Edit Vent Parameters',
      inputs: [
        {
          name: 'minuteVentilator',
          text: 'Minute Ventilation',
          type: 'input',
          value: 12,
        },
        {
          maxValue: 15,
          name: 'biasFlow',
          text: 'Bias Flow',
          type: 'input',
          value: 0,
        },
        {
          maxValue: 50,
          name: 'circuitLeak',
          text: 'Circuit Leak',
          type: 'input',
          value: 0,
        },
        {
          maxValue: 100,
          minValue: 21,
          name: 'fio2',
          step: 1,
          text: 'FiO2',
          value: 100,
        },
        {
          maxValue: 60,
          minValue: 0,
          name: 'rr',
          text: 'RR',
          value: 20,
        },
        {
          maxValue: 5,
          minValue: 0,
          name: 'expiratoryTime',
          step: 0.1,
          text: 'Expiratory Time',
          value: 2,
        },
      ],
    },
    consumables: [
      {
        id: '61',
        title: CONSUMABLE_TYPE.CIRCUIT,
        costPerUnit: 33.33,
        replacementInterval: 8,
        totalQuantity: 23,
        totalCost: 766.59,
      },
      {
        id: '62',
        title: CONSUMABLE_TYPE.VENTILATOR_FILTER,
        costPerUnit: 1.7,
        replacementInterval: 14,
        totalQuantity: 13,
        totalCost: 22.1,
      },
      {
        id: '63',
        title: CONSUMABLE_TYPE.MOISTURE_EXCHANGER,
        costPerUnit: 2.02,
        replacementInterval: 14,
        totalQuantity: 13,
        totalCost: 26.26,
      },
      {
        id: '64',
        title: CONSUMABLE_TYPE.HUMIDIFICATION_H2O,
        costPerUnit: 10.81,
        replacementInterval: 8,
        totalQuantity: 23,
        totalCost: 248.63,
      },
      {
        id: '65',
        title: CONSUMABLE_TYPE.MASK,
        costPerUnit: 0.88,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 0.88,
      },
      {
        id: '66',
        title: CONSUMABLE_TYPE.ENDOTRACHEAL_TUBE,
        costPerUnit: 0.62,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 0.62,
      },
      {
        id: '67',
        title: CONSUMABLE_TYPE.SUCTION_CATHETER,
        costPerUnit: 0.65,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 0.65,
      },
      {
        id: '68',
        title: CONSUMABLE_TYPE.INLINE_SUCTION_CATHETER,
        costPerUnit: 15.91,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 15.91,
      },
    ],
  },
  {
    id: '7',
    title: DEVICE_TYPE.ANESTHESIA_MACHINE_50_FIO2_AT_2LPM,
    consumption: 2,
    costPerUnit: 12,
    isSelected: true,
    litersPerDayAllPatients: 0,
    patients: 24,
    percentage: 0,
    replacementInterval: 24,
    totalCost: 118,
    totalQuantity: 1,
    subtitle: '',
    edit: {
      type: FLOW_PARAMETERS,
      title: EDIT_FLOW_PARAMETERS,
      inputs: [
        {
          name: 'flowRate',
          text: FLOW_RATE_LPM,
          value: 2,
          type: 'input',
        },
        {
          name: 'fio2',
          text: 'FiO2',
          value: 50,
          minValue: 0,
          maxValue: 100,
          step: 1,
        },
        {
          name: 'hoursUsedPerDay',
          text: 'Hours used per day',
          value: 12,
          minValue: 1,
          maxValue: 24,
          step: 1,
        },
      ],
    },
    consumables: [
      {
        id: '71',
        title: CONSUMABLE_TYPE.CIRCUIT,
        costPerUnit: 20,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 1,
      },
      {
        id: '72',
        title: CONSUMABLE_TYPE.VENTILATOR_FILTER,
        costPerUnit: 1.7,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '73',
        title: CONSUMABLE_TYPE.MOISTURE_EXCHANGER,
        costPerUnit: 2.2,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '74',
        title: CONSUMABLE_TYPE.MASK,
        costPerUnit: 0.61,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '75',
        title: CONSUMABLE_TYPE.ENDOTRACHEAL_TUBE,
        costPerUnit: 0.62,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '76',
        title: CONSUMABLE_TYPE.SUCTION_CATHETER,
        costPerUnit: 0.65,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '77',
        title: CONSUMABLE_TYPE.CO2_ABSORBENT,
        costPerUnit: 25,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '78',
        title: CONSUMABLE_TYPE.RESERVOIR_BAG,
        costPerUnit: 1,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
    ],
  },
  {
    id: '8',
    title: DEVICE_TYPE.ANESTHESIA_MACHINE_100_FIO2_AT_2LPM,
    consumption: 2,
    costPerUnit: 12,
    isSelected: true,
    litersPerDayAllPatients: 0,
    patients: 0,
    percentage: 0,
    replacementInterval: 24,
    totalCost: 1181.64,
    totalQuantity: 1,
    subtitle: '',
    edit: {
      type: FLOW_PARAMETERS,
      title: EDIT_FLOW_PARAMETERS,
      inputs: [
        {
          name: 'flowRate',
          text: FLOW_RATE_LPM,
          value: 2,
          type: 'input',
        },
        {
          name: 'fio2',
          text: 'FiO2',
          value: 100,
          minValue: 0,
          maxValue: 100,
          step: 1,
        },
        {
          name: 'hoursUsedPerDay',
          text: 'Hours used per day',
          value: 12,
          minValue: 1,
          maxValue: 24,
          step: 1,
        },
      ],
    },
    consumables: [
      {
        id: '81',
        title: CONSUMABLE_TYPE.CIRCUIT,
        costPerUnit: 20,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 1,
      },
      {
        id: '82',
        title: CONSUMABLE_TYPE.VENTILATOR_FILTER,
        costPerUnit: 1.7,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '83',
        title: CONSUMABLE_TYPE.MOISTURE_EXCHANGER,
        costPerUnit: 2.2,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '84',
        title: CONSUMABLE_TYPE.MASK,
        costPerUnit: 0.61,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '85',
        title: CONSUMABLE_TYPE.ENDOTRACHEAL_TUBE,
        costPerUnit: 0.62,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '86',
        title: CONSUMABLE_TYPE.SUCTION_CATHETER,
        costPerUnit: 0.65,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '87',
        title: CONSUMABLE_TYPE.CO2_ABSORBENT,
        costPerUnit: 25,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
      {
        id: '88',
        title: CONSUMABLE_TYPE.RESERVOIR_BAG,
        costPerUnit: 1,
        replacementInterval: 1,
        totalQuantity: 1,
        totalCost: 2,
      },
    ],
  },
  {
    id: '9',
    title: DEVICE_TYPE.OXYGEN,
    consumption: 2,
    costPerUnit: 0,
    isSelected: true,
    litersPerDayAllPatients: 0,
    patients: 0,
    percentage: 0,
    replacementInterval: 0,
    totalCost: 0,
    totalQuantity: 0,
    consumables: [],
    subtitle: '',
  },
];

const TYPES_OF_LOCATIONS = {
  BASIC_MODE: 'basic mode',
  INPATIENT_WARD: 'Inpatient Ward',
  OPERATING_ROOMS_OR: 'Operating Rooms (OR)',
  EMERGENCY_DEPARTMENT: 'Emergency Department',
  INTENSIVE_CARE_UNIT_ICU: 'Intensive Care Unit (ICU)',
  POST_ANESTHESIA_CARE_UNIT: 'Post Anesthesia Care Unit',
  STEP_DOWN_UNIT: 'Step Down Unit',
};

const CALCULATED_MODEL_COSTS_AND_CONSUMABLES = {
  [TYPES_OF_LOCATIONS.BASIC_MODE]: {
    id: 1,
    totalPatients: 185,
    showDecimals: false,
    [DEVICE_TYPE.NASAL_CANNULA]: (patients, severity) => patients * (0.9 - 0.15 * severity),
    [DEVICE_TYPE.FACEMASK]: (patients, severity) => patients * (0.0125 + 0.01875 * severity),
    [DEVICE_TYPE.FACEMASK_WITH_RESERVOIR]: (patients, severity) =>
      patients * (0.0125 + 0.01875 * severity),
    [DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA]: (patients, severity) =>
      patients * (0.0375 + 0.05625 * severity),
    [DEVICE_TYPE.CPAP_OR_NIPPV]: (patients, severity) => patients * (0.005 + 0.0075 * severity),
    [DEVICE_TYPE.VENTILATOR]: (patients, severity) => patients * (0.0325 + 0.04875 * severity),
  },
  [TYPES_OF_LOCATIONS.INPATIENT_WARD]: {
    id: 2,
    totalPatients: 100,
    showDecimals: false,
    [DEVICE_TYPE.NASAL_CANNULA]: (patients, severity) => patients * (1 - 0.075 * severity),
    [DEVICE_TYPE.FACEMASK]: (patients, severity) => patients * (0.0 + 0.05 * severity),
    [DEVICE_TYPE.FACEMASK_WITH_RESERVOIR]: (patients, severity) =>
      patients * (0.0 + 0.025 * severity),
    [DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA]: (patients, severity) => patients * (0.0 * severity),
    [DEVICE_TYPE.CPAP_OR_NIPPV]: (patients, severity) => patients * (0.0 * severity),
    [DEVICE_TYPE.VENTILATOR]: (patients, severity) => patients * (0.0 * severity),
  },
  [TYPES_OF_LOCATIONS.OPERATING_ROOMS_OR]: {
    id: 3,
    totalPatients: 5,
    showDecimals: false,
    [DEVICE_TYPE.NASAL_CANNULA]: (patients, severity) => patients * (0.15 - 0.03 * severity),
    [DEVICE_TYPE.FACEMASK]: (patients, severity) => patients * (0.15 - 0.03 * severity),
    [DEVICE_TYPE.FACEMASK_WITH_RESERVOIR]: (patients, severity) => patients * (0.0 * severity),
    [DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA]: (patients, severity) => patients * (0.0 * severity),
    [DEVICE_TYPE.CPAP_OR_NIPPV]: (patients, severity) => patients * (0.0 * severity),
    [DEVICE_TYPE.VENTILATOR]: (patients, severity) => patients * (0.0 * severity),
    [DEVICE_TYPE.ANESTHESIA_MACHINE_WITH_VENTILATOR]: (patients, severity) =>
      patients * (0.0 * severity),
    [DEVICE_TYPE.ANESTHESIA_MACHINE_50_FIO2_AT_2LPM]: (patients, severity) =>
      patients * (0.5 + 0.025 * severity),
    [DEVICE_TYPE.ANESTHESIA_MACHINE_100_FIO2_AT_2LPM]: (patients, severity) =>
      patients * (0.2 + 0.035 * severity),
  },
  [TYPES_OF_LOCATIONS.EMERGENCY_DEPARTMENT]: {
    id: 4,
    totalPatients: 30,
    showDecimals: false,
    [DEVICE_TYPE.NASAL_CANNULA]: (patients, severity) => patients * (0.81 - 0.1 * severity),
    [DEVICE_TYPE.FACEMASK]: (patients, severity) => patients * (0.06 + 0.02 * severity),
    [DEVICE_TYPE.FACEMASK_WITH_RESERVOIR]: (patients, severity) =>
      patients * (0.05 + 0.02 * severity),
    [DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA]: (patients, severity) =>
      patients * (0.03 + 0.02 * severity),
    [DEVICE_TYPE.CPAP_OR_NIPPV]: (patients, severity) => patients * (0.05 + 0.02 * severity),
    [DEVICE_TYPE.VENTILATOR]: (patients, severity) => patients * (0.01 + 0.02 * severity),
  },
  [TYPES_OF_LOCATIONS.INTENSIVE_CARE_UNIT_ICU]: {
    id: 5,
    totalPatients: 10,
    showDecimals: false,
    [DEVICE_TYPE.NASAL_CANNULA]: (patients, severity) => patients * (0.4 - 0.1 * severity),
    [DEVICE_TYPE.FACEMASK]: (patients, severity) => patients * (0.05 + 0.02 * severity),
    [DEVICE_TYPE.FACEMASK_WITH_RESERVOIR]: (patients, severity) =>
      patients * (0.05 + 0.02 * severity),
    [DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA]: (patients, severity) =>
      patients * (0.2 + 0.02 * severity),
    [DEVICE_TYPE.CPAP_OR_NIPPV]: (patients, severity) => patients * (0.1 + 0.02 * severity),
    [DEVICE_TYPE.VENTILATOR]: (patients, severity) => patients * (0.2 + 0.02 * severity),
  },
  [TYPES_OF_LOCATIONS.POST_ANESTHESIA_CARE_UNIT]: {
    id: 6,
    totalPatients: 10,
    showDecimals: true,
    [DEVICE_TYPE.NASAL_CANNULA]: (patients, severity) => patients * (0.6 - 0.057 * severity),
    [DEVICE_TYPE.FACEMASK]: (patients, severity) => patients * (0.2 + 0.033 * severity),
    [DEVICE_TYPE.FACEMASK_WITH_RESERVOIR]: (patients, severity) =>
      patients * (0.2 + 0.024 * severity),
    [DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA]: (patients, severity) => patients * (0.0 * severity),
    [DEVICE_TYPE.CPAP_OR_NIPPV]: (patients, severity) => patients * (0.0 * severity),
    [DEVICE_TYPE.VENTILATOR]: (patients, severity) => patients * (0.0 * severity),
  },
  [TYPES_OF_LOCATIONS.STEP_DOWN_UNIT]: {
    id: 7,
    totalPatients: 15,
    showDecimals: false,
    [DEVICE_TYPE.NASAL_CANNULA]: (patients, severity) => patients * (0.7 - 0.08 * severity),
    [DEVICE_TYPE.FACEMASK]: (patients, severity) => patients * (0.1 + 0.02 * severity),
    [DEVICE_TYPE.FACEMASK_WITH_RESERVOIR]: (patients, severity) =>
      patients * (0.1 + 0.02 * severity),
    [DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA]: (patients, severity) =>
      patients * (0.05 + 0.02 * severity),
    [DEVICE_TYPE.CPAP_OR_NIPPV]: (patients, severity) => patients * (0.05 + 0.02 * severity),
    [DEVICE_TYPE.VENTILATOR]: (patients, severity) => patients * (0.0 * severity),
  },
};

const EQUIPMENT_BY_COST_LOCATION = {
  [TYPES_OF_LOCATIONS.BASIC_MODE]: [
    DEVICE_TYPE.NASAL_CANNULA,
    DEVICE_TYPE.FACEMASK,
    DEVICE_TYPE.FACEMASK_WITH_RESERVOIR,
    DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA,
    DEVICE_TYPE.CPAP_OR_NIPPV,
    DEVICE_TYPE.VENTILATOR,
  ],
  [TYPES_OF_LOCATIONS.INPATIENT_WARD]: [
    DEVICE_TYPE.NASAL_CANNULA,
    DEVICE_TYPE.FACEMASK,
    DEVICE_TYPE.FACEMASK_WITH_RESERVOIR,
  ],
  [TYPES_OF_LOCATIONS.OPERATING_ROOMS_OR]: [
    DEVICE_TYPE.NASAL_CANNULA,
    DEVICE_TYPE.FACEMASK,
    DEVICE_TYPE.ANESTHESIA_MACHINE_50_FIO2_AT_2LPM,
    DEVICE_TYPE.ANESTHESIA_MACHINE_100_FIO2_AT_2LPM,
  ],
  [TYPES_OF_LOCATIONS.EMERGENCY_DEPARTMENT]: [
    DEVICE_TYPE.NASAL_CANNULA,
    DEVICE_TYPE.FACEMASK,
    DEVICE_TYPE.FACEMASK_WITH_RESERVOIR,
    DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA,
    DEVICE_TYPE.CPAP_OR_NIPPV,
    DEVICE_TYPE.VENTILATOR,
  ],
  [TYPES_OF_LOCATIONS.INTENSIVE_CARE_UNIT_ICU]: [
    DEVICE_TYPE.NASAL_CANNULA,
    DEVICE_TYPE.FACEMASK,
    DEVICE_TYPE.FACEMASK_WITH_RESERVOIR,
    DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA,
    DEVICE_TYPE.CPAP_OR_NIPPV,
    DEVICE_TYPE.VENTILATOR,
  ],
  [TYPES_OF_LOCATIONS.POST_ANESTHESIA_CARE_UNIT]: [
    DEVICE_TYPE.NASAL_CANNULA,
    DEVICE_TYPE.FACEMASK,
    DEVICE_TYPE.FACEMASK_WITH_RESERVOIR,
  ],
  [TYPES_OF_LOCATIONS.STEP_DOWN_UNIT]: [
    DEVICE_TYPE.NASAL_CANNULA,
    DEVICE_TYPE.FACEMASK,
    DEVICE_TYPE.FACEMASK_WITH_RESERVOIR,
    DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA,
    DEVICE_TYPE.CPAP_OR_NIPPV,
  ],
};

const OMITTED_DEVICES_FOR_BASIC_MODE = [
  DEVICE_TYPE.ANESTHESIA_MACHINE_100_FIO2_AT_2LPM,
  DEVICE_TYPE.ANESTHESIA_MACHINE_50_FIO2_AT_2LPM,
  DEVICE_TYPE.ANESTHESIA_MACHINE_WITH_VENTILATOR,
  DEVICE_TYPE.OXYGEN,
];

const DEVICE_TYPES_TO_ANESTHESIA = [
  DEVICE_TYPE.ANESTHESIA_MACHINE_100_FIO2_AT_2LPM,
  DEVICE_TYPE.ANESTHESIA_MACHINE_50_FIO2_AT_2LPM,
];

const BASIC_COST_AND_CONSUMABLES_MODEL = MODEL_COST_AND_CONSUMABLES.filter((device) => {
  return !OMITTED_DEVICES_FOR_BASIC_MODE.includes(device.title);
});

// keys i18n
const SUBTITLES_DESCRIPTION = {
  [DEVICE_TYPE.VENTILATOR]: {
    subtitleKey: 'consumables_and_costs.description_subtitle_ventilator',
  },
  [DEVICE_TYPE.HIGH_FLOW_NASAL_CANNULA]: {
    subtitleKey: 'consumables_and_costs.description_subtitle_high_flow_nasal_cannula',
  },
  [DEVICE_TYPE.CPAP_OR_NIPPV]: {
    subtitleKey: 'consumables_and_costs.description_subtitle_cpap_or_nippv',
  },
};

const DEFAULT_RESULTS_COSTS_AND_CONSUMABLES = {
  dailyConsumption: 0,
  daysToForecast: 1,
  totalCost: 0,
  totalHospitalizedPatients: 0,
  totalPredictedConsumption: 0,
};

const DEFAULT_COST_AND_CONSUMABLE_SCENARIO = {
  allDevicesInScenario: [],
  currencyType: CURRENCY_TYPE.USD,
  isAdvancedMode: false,
  location: null,
  mode: 'Basic',
  numberOfOxygenPatients: 0,
  percentageOfOxygenPatients: 0,
  severityLevel: 0,
  totalPatients: 0,
  totalSystemLeakageAmount: 0,
};

const DEFAULT_INFORMATION_FOR_REPORT = {
  allDevicesInScenario: [],
  currencyType: CURRENCY_TYPE.USD,
  dailyConsumption: 0,
  dailyConsumptionWithUnit: { totalDailyConsumption: 0, unit: '' },
  daysToForecast: 1,
  isAdvancedMode: false,
  location: null,
  mode: 'Basic',
  numberOfOxygenPatients: 0,
  percentageOfOxygenPatients: 0,
  predictedConsumptionWithUnit: { totalPredictedInDays: 0, unit: '' },
  predictedDevices: [],
  scenarioDevices: [],
  severityLevel: 0,
  totalCost: 0,
  totalCostInDay: 0,
  totalHospitalizedPatients: 0,
  totalPatients: 0,
  totalPredictedConsumption: 0,
  totalSystemLeakageAmount: 0,
};

export {
  BASIC_COST_AND_CONSUMABLES_MODEL,
  CALCULATED_MODEL_COSTS_AND_CONSUMABLES,
  CONSUMABLE_TYPE,
  CURRENCY_TYPE,
  DEFAULT_COST_AND_CONSUMABLE_SCENARIO,
  DEFAULT_INFORMATION_FOR_REPORT,
  DEFAULT_RESULTS_COSTS_AND_CONSUMABLES,
  DEVICE_TYPE,
  DEVICE_TYPES_TO_ANESTHESIA,
  EQUIPMENT_BY_COST_LOCATION,
  MODEL_COST_AND_CONSUMABLES,
  OPTIONS_CURRENCY,
  PROPERTIES_LIST,
  SUBTITLES_DESCRIPTION,
  TYPES_OF_HEADERS_COSTS,
  TYPES_OF_LOCATIONS,
};
