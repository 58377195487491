<template>
  <div class="min-h-full font-Lato box-border">
    <router-view />
  </div>
</template>

<script setup>
import { MixpanelTracking } from '@/plugins/mixpanel/mixpanel';
import { onMounted } from 'vue';

onMounted(() => {
  MixpanelTracking.getInstance().trackPageViewed();
});
</script>
<style>
@font-face {
  font-family: 'Lato';
  src: local('Lato Thin'), url('./assets/fonts/Lato-Thin.ttf') format('truetype');
  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Thin Italic'), url('./assets/fonts/Lato-ThinItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Extra Light'), url('./assets/fonts/Lato-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Light'), url('./assets/fonts/Lato-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Light Italic'), url('./assets/fonts/Lato-LightItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('./assets/fonts/Lato-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Italic'), url('./assets/fonts/Lato-Italic.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Medium'), url('./assets/fonts/Lato-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Semibold'), url('./assets/fonts/Lato-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Bold'), url('./assets/fonts/Lato-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Bold Italic'), url('./assets/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Extra Bold'), url('./assets/fonts/Lato-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Black'), url('./assets/fonts/Lato-Black.ttf') format('truetype');
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Black Italic'), url('./assets/fonts/Lato-BlackItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 900;
  font-style: italic;
}

.Vue-Toastification__container {
  z-index: 99999 !important;
}
</style>
