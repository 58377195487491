const About = () => import('@/modules/General/views/AboutComponent.vue');
const GlobalMaps = () => import('@/modules/General/views/GlobalMaps.vue');

const moduleRoute = {
  name: 'general',
  path: '',
  children: [
    {
      name: 'about',
      path: 'about',
      component: About,
    },
    {
      name: 'GlobalMaps',
      path: 'global-maps',
      component: GlobalMaps,
    },
  ],
};

export default (router) => {
  router.addRoute('main', moduleRoute);
};
