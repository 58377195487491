import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

const breakpoints = useBreakpoints(breakpointsTailwind);

const isBreakpointGreaterOrEqualToMD = breakpoints.greaterOrEqual('md');
const isBreakpointSmallerOrEqualToMD = breakpoints.smallerOrEqual('md');
const isBreakpointSmallerOrEqualToSM = breakpoints.smallerOrEqual('sm');
const isBreakpointSmallerOrEqualToLG = breakpoints.smallerOrEqual('lg');
const isBreakpointGreaterOrEqualToLG = breakpoints.greaterOrEqual('lg');

export {
  isBreakpointGreaterOrEqualToMD,
  isBreakpointSmallerOrEqualToMD,
  isBreakpointSmallerOrEqualToSM,
  isBreakpointSmallerOrEqualToLG,
  isBreakpointGreaterOrEqualToLG,
};

