const Main = () => import("./Main")
const Login = () => import ("./views/Login")
const Register = () => import ("./views/Register")
const RegisterEmailConfirmation = () => import ("./views/RegisterEmailConfirmation")

const moduleRoute = {
  name: 'auth',
  path: '/auth',
  component: Main,
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
    },
    {
      path: '/email-confirmation',
      name: 'emailConfirmation',
      component: RegisterEmailConfirmation,
    },
  ],
};

export default router => {
  router.addRoute('auth', moduleRoute);
};