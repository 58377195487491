 export const measurementOptions  = [
  {
    text: 'liters',
    value: 'liters',
    unit: 'liters',
  },
  {
    text: 'cubic_meters',
    value: 'cubic_meters',
    unit: 'm³',
  },
  {
    text: 'kilograms',
    value: 'kilograms',
    unit: 'Kg',
  }
];