const languages = [
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Espanol' },
  { value: 'fr', text: 'Français' },
  { value: 'vi', text: 'Tiếng Việt' },
  { value: 'pt', text: 'Português' },
];

const navigationMenuItems = [
  {
    text: 'O2 Demand menu',
    value: 'o2demand',
  },
  {
    text: 'O2 Supply menu',
    value: 'o2supply',
  },
  {
    text: 'SpO2 to PaO2 menu',
    value: 'sp2topao2',
  },
  {
    text: 'Cylinder Duration menu',
    value: 'cylinderduration',
  },
  {
    text: 'Cylinder Size menu',
    value: 'cylindersize',
  },
  {
    text: 'About us',
    value: 'about',
    hidden: true,
  },
  {
    text: 'viewO2OpenGlobalMap.globalOxygenConsumptionMap',
    value: 'GlobalMaps',
    hidden: true,
  },
  {
    text: 'Consumables & Cost',
    value: 'consumables-cost',
  },
];

const buttonsHeader = [
  {
    name: 'O₂ Demand',
    route: 'o2demand',
    disabled: false,
  },
  {
    name: 'O₂ Supply',
    route: 'o2supply',
    disabled: false,
  },
  {
    name: 'SpO₂ to PaO₂',
    route: 'sp2topao2',
    disabled: false,
  },
  {
    name: 'Cylinder Duration',
    route: 'cylinderduration',
    disabled: false,
  },
  {
    name: 'Cylinder Size',
    route: 'cylindersize',
    disabled: false,
  },
  {
    name: 'about',
    route: 'about',
    hidden: true,
  },
  {
    name: 'viewO2OpenGlobalMap.globalOxygenConsumptionMap',
    route: 'GlobalMaps',
    hidden: true,
  },
  {
    name: 'Consumables & Cost menu',
    route: 'consumables-cost',
    hidden: false,
    isBeta: true,
  },
];

const topMenuLinks = {
  about: 'https://opencriticalcare.org/about/',
  criticalCare: 'https://opencriticalcare.org',
};

export { buttonsHeader, languages, navigationMenuItems, topMenuLinks };
