import { capitalizeFirstLetter } from '@/common/utils';

function optionsTranslation(options, t) {
  return options.map((option) => {
    const text = capitalizeFirstLetter(t(option.text));
    return {
      ...option,
      text,
    };
  });
}

export { optionsTranslation };
