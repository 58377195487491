const loginPropertiesAdapter = (user) => {
  return {
    userId: user.id,
    email: user.email,
    role: user.role,
    country: user.country,
    hospital: user.hospital,
    lastSignInTime: user.lastSignInTime,
  };
};

const registerPropertiesAdapter = (user) => {
  return {
    userId: user?.id,
    email: user?.email,
    role: user?.role,
    country: user?.country,
    hospital: user?.hospital,
    lastSignInTime: user?.lastSignInTime,
  };
};

const useMyOwnDataDailyConsumptionResultsAdapter = ({
  userEmail,
  selectedDate,
  dailyConsumption,
  locationName,
  hospital,
  consumptionByDevices,
}) => {
  return {
    email: userEmail ?? 'guest',
    selectedDate,
    dailyConsumption: Math.round(dailyConsumption),
    locationName: locationName ?? 'basic mode',
    hospital: hospital,
    consumptionByDevices,
  };
};

export {
  loginPropertiesAdapter,
  registerPropertiesAdapter,
  useMyOwnDataDailyConsumptionResultsAdapter,
};
